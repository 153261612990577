



































































































import { Component, Watch, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import controller from '@/app/ui/controllers/PublicVoucherController'
import { Utils } from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import { PUBLIC_VOUCHER_HISTORY_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { EnumVoucherPurpose, VOUCHER_PURPOSES_EDIT } from '@/app/infrastructures/misc/Constants/publicVoucher'
import { IParameter } from '@/data/infrastructures/misc/interfaces/publicVoucher'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { IHeaderCell } from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import { IDataCell } from '@/app/infrastructures/misc/Constants/scheduleOrder'

dayjs.extend(dayjsUTC)

type fakeDictionary<T> = { [key: string]: T }

@Component({
  components: {
    EmptyState,
    DataTableV2,
    DateTimePickerV2,
    DataTable,
    DownloadIcon,
    PaginationNav,
    Button,
  },
})
export default class PublicVoucherUsage extends Vue {
  controller = controller
  form: {
    startDate: Date | undefined
    endDate: Date | undefined
  } = {
    startDate: undefined,
    endDate: undefined,
  }
  parameters: IParameter = {
    page: 1,
    perPage: PUBLIC_VOUCHER_HISTORY_PAGINATION,
    startDate: null,
    endDate: null,
  }
  headersVal = [
    this.headerCellMapper('Voucher Name', '200px'),
    this.headerCellMapper('Customer ID', '180px'),
    this.headerCellMapper('Customer Name', '220px'),
    this.headerCellMapper('Voucher Purpose', '200px'),
    this.headerCellMapper('Voucher Code', '180px'),
    this.headerCellMapper('Redeem Date', '160px'),
    this.headerCellMapper('Voucher Amount', '180px'),
  ]
  hasData = false
  isInit = true

  created(): void {
    const queries = <Record<string, never>>this.$route.query
    this.parameters = {
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage:
        Utils.alwaysNumber(queries.perPage) ||
        PUBLIC_VOUCHER_HISTORY_PAGINATION,
      startDate: queries.startDate,
      endDate: queries.endDate,
    }

    controller.getVoucherUsage({
      id: this.$route.params.id,
      params: this.params,
    })
  }

  get isParcelPoint(): boolean {
    return (
      controller.publicVoucherDetail.purpose?.toUpperCase() ===
      EnumVoucherPurpose.PARCEL_POINT
    )
  }

  get isDiscountByPercentage(): boolean {
    return (
      controller.publicVoucherDetail.purpose?.toUpperCase() ===
      EnumVoucherPurpose.DISCOUNT_PERCENTAGE
    )
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  get headers(): IHeaderCell[] {
    if (this.isDiscountByPercentage) {
      return [
        this.headerCellMapper('Voucher Name', '200px'),
        this.headerCellMapper('Customer ID', '180px'),
        this.headerCellMapper('Customer Name', '220px'),
        this.headerCellMapper('Voucher Purpose', '200px'),
        this.headerCellMapper('Voucher Code', '180px'),
        this.headerCellMapper('Redeem Date', '160px'),
        this.headerCellMapper('% Discount', '120px'),
        this.headerCellMapper('Voucher Amount', '180px'),
        this.headerCellMapper('Used Date', '160px'),
        this.headerCellMapper('Used Amount', '180px'),
      ]
    }

    if(this.isParcelPoint) {
      return [
        ...this.headersVal,
        this.headerCellMapper( 'Parcel Poin Redeemed', '180px'),
        ]
    }

    return this.headersVal
  }

  get voucherName(): string {
    return controller.publicVoucherDetail.name || '-'
  }

  get purpose(): string {
    return VOUCHER_PURPOSES_EDIT.find(e => e.value === controller.publicVoucherDetail?.purpose)?.label || '-'
  }

  get params(): IParameter {
    return {
      ...this.parameters,
      startDate: this.parameters.startDate
        ? dayjs(this.parameters.startDate, 'YYYY-MM-DD')
            .utc()
            .toISOString()
        : null,
      endDate: this.parameters.endDate
        ? dayjs(this.parameters.endDate, 'YYYY-MM-DD')
            .endOf('days')
            .utc()
            .toISOString()
        : null,
      sortBy: 'NEWEST',
    }
  }

  get historyData(): Array<Array<unknown>> {
    const data = []
    if (controller.historyData && controller.historyData.length > 0) {
      this.hasData = true
      // using FOR Loop for better looping performance
      for (let i = 0; i < controller.historyData.length; i++) {
        let finalData = [
          this.tableCellMapper(controller.historyData[i].name || '', '200px'),
          this.tableCellMapper(controller.historyData[i].customerId || '', '180px'),
          this.tableCellMapper(controller.historyData[i].customerName || '', '220px'),
          this.tableCellMapper(this.purpose, '200px'),
          this.tableCellMapper(controller.historyData[i].code || '', '180px'),
          this.tableCellMapper(  Utils.formatDate(controller.historyData[i].createdAt || '', 'DD/MM/YYYY', '-'),'160px'),
        ]

        if (this.isParcelPoint) {
          // amount
          finalData.push(this.tableCellMapper(`Rp${Utils.currencyDigit(controller.historyData[i].amount || 0)}`, '180px'))
          finalData.push(this.tableCellMapper(`Rp${Utils.currencyDigit(
            controller.historyData[i].usedAmount || 0
          )}`, '180px'))
        }

        if (this.isDiscountByPercentage) {
          const percentage = controller.historyData[i].voucherPercentage ? controller.historyData[i].voucherPercentage + '%' : ''

          // amount
          finalData.push(this.tableCellMapper(`Rp${Utils.currencyDigit(controller.historyData[i].voucherAmount || 0)}`, '180px'))
          // %discount
          finalData.splice(6, 0, this.tableCellMapper(percentage, '120px'))
          // Used Date
          finalData.push(this.tableCellMapper(Utils.formatDate(controller.historyData[i].vouchedUsedAt || '', 'DD/MM/YYYY', '-'), '160px'))
          // Used Amount
          finalData.push(this.tableCellMapper(`Rp${Utils.currencyDigit(
            controller.historyData[i].vouchedUsed || 0
          )}`, '180px'))
        }
        data.push(finalData)
      }
    } else {
      this.hasData = false
    }
    return data
  }

  private onRedeemStartDateChange(date: Date): void {
    this.form.startDate = date
    if (this.form.startDate && date) {
      this.parameters.startDate = Utils.formatDate(date.toLocaleDateString())
    } else {
      this.parameters.startDate = undefined
    }

    if (this.form.endDate) {
      const startDate = Utils.formatDate(date.toLocaleDateString(), 'DD-MMMM-YYYY')
      const endDate = Utils.formatDate(this.form.endDate.toLocaleDateString(), 'DD-MMMM-YYYY')
      if (this.form.endDate < date && startDate !== endDate) {
        this.form.endDate = undefined
        this.parameters.endDate = undefined
      }
    }
  }

  private onRedeemEndDateChange(date: Date): void {
    this.form.endDate = date
    if (this.form.endDate && date) {
      this.parameters.endDate = Utils.formatDate(date.toLocaleDateString())
    } else {
      this.parameters.endDate = undefined
    }
  }

  @Watch('params')
  onParamsChanged(val: fakeDictionary<IParameter>): void {
    const formatDate = 'YYYY-MM-DD'
    this.$router.replace({
      query: {
        ...val,
        startDate: this.parameters.startDate
          ? Utils.formatDate(this.parameters.startDate, formatDate)
          : null,
        endDate: this.parameters.endDate
          ? Utils.formatDate(this.parameters.endDate, formatDate)
          : null,
      },
    })
  }

  get isSaldoPoint(): boolean {
    return (
      controller.publicVoucherDetail.purpose?.toUpperCase() ===
      EnumVoucherPurpose.SALDO_POINT
    )
  }

  private fetchHistory(resetPage?: boolean): void {
    this.isInit = false
    if (resetPage) {
      this.parameters.page = 1
    }

    const params = {
      id: this.$route.params.id,
      params: this.params,
    }

    if (this.isSaldoPoint) {
      controller.getVoucherHistory(params)
    } else {
      controller.getVoucherHistoryV2(params)
    }
  }

  private getExportUrl(): void {
    if (this.hasData) {
      const params = {
        id: this.$route.params.id,
        params: this.params,
      }

      if (this.isSaldoPoint) {
        controller.getExportedFile(params)
      } else {
        controller.getExportedFileV2(params)
      }
    }
  }
}
